import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Layout/Header/Index'
import "./nonitpayment.scss"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { axiosApi } from '../../_helper/api_helper';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from '../../Layout/Footer/Index';
import { Helmet } from 'react-helmet';
import LocalPlayIcon from '@mui/icons-material/LocalPlay';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import BallotIcon from '@mui/icons-material/Ballot';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useNavigate } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SuccessModalFreelancer from '../ReferToEarn/SuccessModalFreelancer';

const PremimumNonIt = () => {
    const clientId = localStorage.getItem("client_Id");
    const navigate = useNavigate();
    const plansRef = useRef(null);


    const [nonitCreditPoints, setNonitCreditPoints] = useState([]);
    const [architectPlan, setAnnuallyplan] = useState([]);
    const [creditSingleData, setCreditSingleData] = useState({});
     
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [razorpayPaymentId, setRazorpayPaymentId] = useState('');
    const [orderResponse, setOrderIdResponse] = useState('')
    const [showCouponInput, setShowCouponInput] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [planType, setPlanType] = useState('');

    const worksPlans = [
        { id: 1, title: "Choose Your Plan", content: "Select the subscription plan that best suits your needs. Whether you’re looking for basic access or premium features, we have the right option for you", icon: <LocalPlayIcon /> },
        { id: 2, title: "Earn Points", content: " Every subscription plan comes with a points system. The longer you stay subscribed, the more points you earn. Points can be used to unlock special features, access additional resources, and apply for exclusive job opportunities.", icon: <PointOfSaleIcon /> },
        { id: 3, title: "Apply Your Points", content: "Use your accumulated points to apply for our authentication jobs. Our system recognizes your commitment and rewards your loyalty, giving you a competitive edge in the job market.", icon: <BallotIcon /> },
        { id: 4, title: "Enjoy Exclusive Benefits", content: "As a subscriber, you’ll receive regular updates, special offers, and invitations to webinars and workshops that can further enhance your skills and career prospects", icon: <AssuredWorkloadIcon /> },
    ]
    const [open, setOpen] = React.useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);

    useEffect(() => {

        nonitCreditApi();
        // architectApi();

    }, []);



    const handleOpenDialog = (plan, type) => {
        const clientId = localStorage.getItem("client_Id");
        if (!clientId) {
            navigate('/register-as-freelancer'); // Redirect to login if clientId is not found
        } else {
            setSelectedPlan(plan);
            setPlanType(type);
            setOpen(true);
            creditApiById(plan, type);
        }
    };


    const scrollToPlans = () => {
        if (plansRef.current) {
            plansRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleRemoveCouponClick = () => {
        setCouponCode('');
        setShowCouponInput(false);
    };


    
    const nonitCreditApi = async () => {
        try {
            // Fetch Starter Lead Package from /nonit-subscription-plan
            const response = await axiosApi.get(`/nonitsubscriptionpremium-subscription`);
            const plans = response?.data;
  
            const sortedPlans = plans.sort((a, b) => {
                const order = ["Standard", "Advance", "Pro"];
                return order.indexOf(a.plan_type.name) - order.indexOf(b.plan_type.name);
            });
    
            
    
            
            setNonitCreditPoints(sortedPlans);
    
        } catch (error) {
            console.error("Error fetching plans:", error);
        }
    }




    

    useEffect(() => {
        if (open) {
            setCouponCode("");
            setShowCouponInput(false);
             


        }
    }, [open]);
    
  
    const creditApiById = async (selectedPlan,planType ) => {
         
        try {
            let response;
    
            // if (planType === "Starter Lead Package") {
 
            //     response = await axiosApi.get(`/nonitsubscriptionpremium-subscription/${selectedPlan}`);
            // } else if (planType === "Advance Lead Package") {
 
            //     response = await axiosApi.get(`/nonitsubscriptionquarterly-subscription/${selectedPlan}`);
            // }
            response = await axiosApi.get(`/nonitsubscriptionpremium-subscription/${selectedPlan}`);
            // Set the data after fetching
            setCreditSingleData(response?.data);
    
            // Handle coupon code if needed
            if (couponCode) {
                setShowSuccessModal(true); // Show the modal if coupon code is provided
            }
    
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.error, {
                    position: 'top-left',
                });
            } else {
                console.error("Payment link creation failed:", error);
                alert("An error occurred. Please try again later.");
            }
        }
    };
    
   

   

    const handleplanSubmit = (e) => {


        e.preventDefault();
        const clientId = localStorage.getItem("client_Id");
        if (!clientId) {
            navigate('/register-as-freelancer'); // Redirect to login if clientId is not found
            return;
        }
        //  for non it
        const baseUrl =  `/nonitsubscriptionpremium-subscriptions/${clientId}/${selectedPlan}`
        // planType === "Architect" ? 
        //     `/nonitsubscriptionpremium-subscription/${clientId}/${selectedPlan}` : 
        // planType === "Starter Lead Package" ? 
        //     `/create-subscription/${clientId}/${selectedPlan}` : 
        // planType === "Advance Lead Package" ? 
        //     `/nonitsubscriptionquarterly-subscriptions/${clientId}/${selectedPlan}` : 
        //     null;


        // axiosApi.post(`/create-payment-link/${clientId}/${selectedPlan}`, {
        axiosApi.post(baseUrl, {
            offer_id: couponCode,
        }).then((response) => {

            setCouponCode("")
            setRazorpayPaymentId(response.data.razorpay_payment_id);
            setOrderIdResponse(response?.data);
            console.log("paymentLink::::",response.data)
            const paymentLink =response.data.payment_link
                // planType === "Architect"
                //     ? response.data.payment_link:
                //     planType === "Advance Lead Package"? response.data.payment_link:
                //     planType === "Starter Lead Package"? response.data.subscription_link:
                //     null
                //     ;

            if (!paymentLink) {
                console.error("Payment link not returned by the backend.");
                alert("Payment link not generated. Please try again later.");
                return;
            }

            // const paymentLink = response.data.subscription_link;
            window.location.href = paymentLink;


        }).catch((error) => {
            if (error.response && error.response.data) {
                toast.error(error.response.data.error, {
                    position: 'top-left',
                });
                setCouponCode("")
                return

            } else {
                console.error("Payment link creation failed:", error);
                alert("An error occurred. Please try again later.");
            }
        });
    }
    const formatPrice = (price) => {
        const numberPrice = Number(price);

        return numberPrice.toLocaleString('en-IN', {
            maximumFractionDigits: 0,
            useGrouping: true
        });
    };


    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <>
            <Helmet>

                <title>Affordable Plans for Freelancing Jobs in India - Nearby Freelancers</title>
                <link rel="icon" href={"/credits"} />
                <meta name="description" content={`Discover affordable plans for freelancing jobs in India. Hire top freelancers and find the best opportunities. Choose a plan that fits your needs today!`} />
                <meta name="keywords" content="Freelancing Jobs in India,
     Freelancing Jobs,
     Jobs for Freelancers,
  Hire Freelancers,
working with freelancers,
Freelance Work" />
                <meta name="Earn credits" content={"Earn credits"} />
                <meta property="og:Nearby Freelancers" content={"og:Nearby Freelancers"} />
                <meta property="og:description" content={"og:description"} />

            </Helmet>
            <Header />
            <div className="buy-points-contianer">
                <div className="buy-points">


                    <img src="image/freelancer-plan-baaner.png" alt="Freelancer working remotely on a freelancing job in India" />

                    <div className="freelancer-plan-content">
                        <h6>Find More Projects, Earn More Income</h6>
                        <h4>Choose the perfect plan to connect with clients and <br /> grow your freelance business.</h4>
                        <button>Pick Your Plan</button>

                    </div>
                </div>

            </div>



            <section className='chooseplan-container-non-it'>

                <div onClick={scrollToPlans} className="choose-plan-non-it">
                    <div className="choose-plan-heading">
                        <h6>FREELANCING PREMIUM PACKAGE FOR </h6>
                        <h3>
                            Non-IT Category 

                        </h3>
                         
                    </div>
                    <div className="choose-plan-content-container-non-it">
                        {nonitCreditPoints.filter((item) => item?.plan_type?.name !== "Membership Package").map((item) => {
                            let features = [
                                // "Project apply",
                                "Dedicated Leads",
                                "Dedicated RM", "Verified Tag",
                                "Trusted Shield",
                                // "Featured in top 5 in your city",
                                "Featured city",
                            ];
                            let includeFeatures = [];
                            let projectLeads = "";
                            let featuredcity = "";
                            let dedicatedLeads = "";
                            if (item?.plan_type?.name === "Standard") {
                                includeFeatures = ["Dedicated RM", "Verified Tag","Trusted Shield"];
                                // projectLeads = "40/ month Online Project Apply";
                                featuredcity = "Featured in top 10 in your city";
                                dedicatedLeads = "upto 75/ Yearly  Leads";
                            }
                            else if (item?.plan_type?.name === "Advance") {
                                includeFeatures = ["Dedicated RM", "Verified Tag", "Trusted Shield",];
                                // projectLeads = "60/ month Online Project Apply";
                                featuredcity = "Featured in top 5 in your city";
                                dedicatedLeads = " upto 145/ Yearly  Leads";
                            }
                             else if (item?.plan_type?.name === "Pro") {
                                includeFeatures = ["Dedicated RM", "Verified Tag", "Trusted Shield",];
                                // projectLeads = "60/ month Online Project Apply";
                                featuredcity = "Featured in top 5 in your city";
                                dedicatedLeads = " upto 300/ Yearly  Leads";
                            }

                            if (projectLeads) {
                                includeFeatures.push(projectLeads);
                            }
                            if (dedicatedLeads) {
                                includeFeatures.push(dedicatedLeads);
                            }
                            if (featuredcity) {
                                includeFeatures.push(featuredcity);
                            }

                            const isFeatureIncluded = (feature) => {
                                // Explicitly handle "Featured city" mapping
                                if (feature === "Featured city") {
                                    return featuredcity !== ""; // Ensure `featuredcity` has a value
                                }
                                return includeFeatures.includes(feature) ||
                                    (feature === "Project apply" && projectLeads) ||
                                    (feature === "Dedicated Leads" && dedicatedLeads);
                            };
                            return (
                                <>
                                    <div className="choose-plan-content">
                                    <div className="save-percentage-freelancer ">
                                    {item?.plan_type?.name === "Advance" ?
                                                         <h5  >
                                            
                                                         <span>Save upto 40%  Off</span>  
                                                    
     
                                                 </h5>:
                                                 item?.plan_type?.name === "Standard" ?    <h5  >
                                            
                                                 <span>Save upto 25%  Off</span>  
                                            

                                         </h5>:item?.plan_type?.name === "Pro" ? <h5  >
                                            
                                            <span>Save upto 60%  Off</span>  
                                       

                                    </h5>:null
                                    }
                       
                                        </div>
                                        <div className={`recommended-badge-container ${item?.plan_type?.name === "Silver" ? "Silver" : ""}`}>
                                            <h5  >
                                                {item?.plan_type?.name === "Silver" && "Most Popular" }

                                            </h5>
                                        </div>
                                        <h4 className='choose-plan-month'>{item?.plan_type?.name}</h4>
                                        <h3><span><CurrencyRupeeIcon style={{ fontWeight: "700" }} className='rupee-sign' /></span> {formatPrice(item?.discounted_price)}  <span className='plan-per-months'> / {item?.months}</span> </h3>
 
                                        <ul className="plan-features">
                                            {features.map((feature, index) => (
                                                <li key={index} className={isFeatureIncluded(feature) ? 'feature-included' : 'feature-not-included'}>
                                                    {isFeatureIncluded(feature) ? (
                                                        <span className='green-checkbox'  ><CheckCircleIcon className='green-checbox-icon' /></span>
                                                    ) : (
                                                        <span ><CancelIcon className='red-checkbox-icon' /></span>
                                                    )}
                                                    {/* {feature} */}
                                                    {/* {feature === "Project apply" && projectLeads ? projectLeads : feature} */}
                                                    <div style={{ fontSize: "13px" }}>
                                                        {feature === "Project apply" && projectLeads ? projectLeads :
                                                            feature === "Dedicated Leads" && dedicatedLeads ? dedicatedLeads :
                                                                feature === "Featured city" && featuredcity ? featuredcity : feature}
                                                    </div>


                                                </li>

                                            ))}

                                        </ul>

                                        <div className='choose-plan-button'>

                                            <button onClick={() => handleOpenDialog(item.id,item?.plan_type?.name,)} className='   '>Choose Plan    </button>



                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>

                </div>

            </section>
         

            <Dialog

                PaperProps={{
                    className: 'dialog-paper', // Apply the CSS class here
                }}
                className='dialog-credit-modelss' open={open} onClose={() => setOpen(false)} maxWidth="md">
                <div className="plan-model-top-content">
                    <div className="plan-model-content-flex1">
                        <h5 style={{ color: "#FF3131", fontWeight: "600", fontSize: "20px" }}>Check Out</h5>
                        <h6 style={{ color: "#4B4B4B", fontWeight: "400", fontSize: "13px" }}>Payment summary</h6>
                    </div>
                    <div style={{ cursor: "pointer" }} className="plan-model-content-flex2" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </div>
                </div>



                <DialogContent>
                    <div className='plan-summary'>
                        <div className="payment-details">
                            <h6>Plan Details:</h6>
                            <h4><CurrencyRupeeIcon />{creditSingleData?.plan_details?.discounted_price}</h4>
                            <h5>{creditSingleData?.plan_details?.plan_type?.name}    </h5>
                            {/* <p>{creditSingleData?.plan_details?.points} Credit Applicable for {creditSingleData?.plan_details?.jobs} projects</p> */}
                        </div>
                        <div className='chooseplan-summary'>

                            {creditSingleData && (
                                <div className='chooseplan-summary-inner'>

                                    <div className='price-plan'>
                                        {creditSingleData?.discount_amount ? (<><h6>Discount Amount:</h6> <span>₹ {creditSingleData?.amount_before_gst}</span></>) : (<><h6>Amount:</h6> <span>₹ {creditSingleData?.amount_before_gst}</span></>)}

                                    </div>
                                    {/* <div className='price-plan'>
                                        <h6><b>{creditSingleData?.plan_type?.name} Price:</b></h6> <span style={{ textDecoration: "line-through" }}>{creditSingleData?.original_price}</span>
                                    </div> */}
                                    <div className='price-plan'>
                                        {/* <h6>Discounted Price:</h6> <span >₹ {creditSingleData?.discounted_price}</span> */}
                                        <h6>GST (18%):</h6> <span >₹ {creditSingleData?.gst_amount}</span>
                                    </div>
                                    <div className='price-plan'>
                                        <h6>Total Amount  :</h6> <span>₹ {creditSingleData?.final_amount}</span>
                                    </div>
                                    {/* <div className='price-plan'>
                                        <h6></h6> {!showCouponInput && <span onClick={handleApplyCouponClick} style={{ color: "#FF3131", fontSize: "15px", cursor: "pointer" }}>Apply coupon</span>}
                                        {showCouponInput && (
                                            <>
                                                <div className="coupon-verify">
                                                    <input type="text" placeholder="Apply offer"
                                                        value={couponCode}
                                                        onChange={handleCouponChange}
                                                    />
                                                     
                                                </div>



                                            </>

                                        )}
                                    </div> */}
                                    {showCouponInput &&
                                        <div className="plan-sub-button">
                                            <button

                                                onClick={handleRemoveCouponClick}

                                            >
                                                Remove
                                            </button>
                                        </div>
                                    }
                                    <hr />

                                    <div className='summary-button'>
                                        <button onClick={handleplanSubmit} type='submit' className='btn btn'>Proceed to payment</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </DialogContent>


            </Dialog >
            <SuccessModalFreelancer
                open={showSuccessModal}
                amountdiscount={creditSingleData?.coupon_discount_percentage}
                onClose={() => setShowSuccessModal(false)}
            />
            <div className="subdscribe-today">
                <div className="subscribe-today-container">
                    <h3>Subscribe Today, Earn Points, and Open Doors to Exciting Job Opportunities!</h3>
                    <p>
                        Welcome to the ultimate career-boosting experience! Our subscription plans offer more than just premium content—they provide a pathway to exciting job opportunities. By subscribing, you not only gain access to exclusive resources and tools but also earn valuable points that can be applied to our authentication job applications.
                    </p>

                </div>
            </div>
            <div className="how-it-works">
                <div className="how-it-works-container">
                    <h1>Here’s how it works:</h1>
                    <div className="how-it-works-section">

                        {worksPlans.map((plan) => (

                            <div className='how-it-works-content' key={plan.id}  >


                                <h6>{plan.title}</h6>
                                <p>{plan.content}</p>




                            </div>
                        ))}
                    </div>

                </div>
            </div>
            <div className="why-subscribe">
                <div className="why-subscribe-container">

                    <h4> Why Subscribe?</h4>
                    <p>Exclusive Access: Get the latest industry insights, tips, and resources to stay ahead of the curve. Earn While You Learn: Accumulate points with every month of subscription and convert them into real career opportunities. Unlock Opportunities: Use your points to apply for our highly sought after authentication jobs, designed for dedicated and ambitious professionals. Stay Updated: Receive priority notifications about job openings, new features, and special events.</p>



                </div>
            </div>
            <div className="faq-seaction-credit-plan">
                <div className="faq-section-inner-subscribe-plan">
                    <h4>Frequently Asked Questions (FAQs)</h4>
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button style={{ boxShadow: "none" }} className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    What is Nearby Freelancers?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Nearby Freelancers is an online platform connecting businesses with local freelance professionals for various projects and jobs.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button style={{ boxShadow: "none" }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How do I register as a freelancer?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    To register as a freelancer, visit our website, click on "Register," fill in your details, and complete your profile

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button style={{ boxShadow: "none" }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    How do businesses post jobs or projects?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Businesses can post jobs or projects by creating an account, selecting "Post a Job" or "Post a Project," and providing the necessary details.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button style={{ boxShadow: "none" }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Is there a fee to join Nearby Freelancers?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Registration is free. We offer various subscription plans for freelancers to access premium features.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button style={{ boxShadow: "none" }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    What types of jobs and projects are available?
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    We offer a wide range of jobs and projects across different industries, including IT, design, writing, marketing, and more.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button style={{ boxShadow: "none" }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    How can I find local projects?
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Use our search filters to narrow down projects based on your location and skills.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                                <button style={{ boxShadow: "none" }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    How do payments work?
                                </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Payments are securely processed through our platform. Freelancers receive payments directly into their accounts after completing a project.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingEight">
                                <button style={{ boxShadow: "none" }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    What is the refund and cancellation policy?
                                </button>
                            </h2>
                            <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Our refund and cancellation policies are clearly outlined on our website. Please refer to the policy page for detailed information.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingNine">
                                <button style={{ boxShadow: "none" }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    Can freelancers work remotely?
                                </button>
                            </h2>
                            <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Yes, freelancers can choose to work remotely or find local projects as per their preference.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTen">
                                <button style={{ boxShadow: "none" }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                    How do I communicate with clients or freelancers?
                                </button>
                            </h2>
                            <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Use our built-in messaging system to communicate securely with clients or freelancers.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <Footer />
        </>
    )
}

export default PremimumNonIt
