import React, { useEffect, useState } from 'react'
import { faTwitter, faFacebookF, faLinkedinIn, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faPhoneVolume, faBuilding, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';
import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link } from 'react-router-dom';
import "./footer.scss"
import { axiosApi } from '../../_helper/api_helper';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BrochureModel from './BrochureModel';
import { useDispatch, useSelector } from 'react-redux';
import { industriesget } from '../../store/industries/action';

const Footer = () => {
  const dispatch = useDispatch();
  const { getIndustries, loading, error } = useSelector(
    (state) => state.IndustriesReducers
  );
  const recuiterId = localStorage.getItem("recuiter_Id");
  const client_Id = localStorage.getItem("client_Id");
  const PDF_FILE = "Freelancing PPT.pdf"
  const [subscribeValues, setSubscribeValues] = useState({
    email: ""
  });
  const [formValues, setformValues] = useState({

    country_name: "India",

  });
  const [categoriesData, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [cities, setCities] = useState([])

  const [showModal, setShowModal] = useState(false);

  const filterCountry = countries.find((d) => d.country_name == formValues?.country_name)?.id
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });

  };
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    // categoriesGet();
    citiesGet();
    countriesApi()
  }, []);

  useEffect(() => {
    dispatch(industriesget());
  }, [dispatch]);
  useEffect(() => {
    if (getIndustries?.length > 0) {
      setCategories(getIndustries);
    }
  }, [getIndustries]);

  const categoriesGet = async () => {
    const response = await axiosApi.get("/industries");
    setCategories(response?.data)
  }
  const citiesGet = async () => {
    const response = await axiosApi.get("/cities");
    setCities(response?.data)
  };
  const countriesApi = async () => {
    const response = await axiosApi.get("/countries");
    setCountries(response?.data)
  }
  const countriesGetById = async () => {
    try {
      const response = await axiosApi.get(`/countries/${filterCountry}/cities`);
      // const sortedData = response?.data?.sort((a, b) =>
      //     a.city_name.localeCompare(b.city_name)
      // );  
      const sortedData = response?.data?.sort((a, b) => {
        // Check if one of the items is "Others" and move it to the end
        if (a.city_name === "Others") return 1;
        if (b.city_name === "Others") return -1;


        return a.city_name.localeCompare(b.city_name);
      });
      setCountryData(sortedData);
    } catch (error) {
      console.error('Error fetching country data:', error);
    }
  };
  useEffect(() => {
    if (filterCountry) {
      countriesGetById();
    }

  }, [filterCountry])
  const submitSubscribe = (e) => {
    e.preventDefault();
    const payload = {
      email: subscribeValues?.email
    }
    axiosApi.post("/newsletter", payload).then((response) => {

      setSubscribeValues({
        email: ""
      })
      toast.success('Thank you for subscribing our letter', {
        position: 'top-left',
      });

    })
  }
  const handlePDF = (url) => {
    if (url) {


      const fileName = url.split("/").pop();


      const aTag = document.createElement("a");
      aTag.href = url;
      aTag.setAttribute("download", fileName);
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    } else {
      console.error("PDF file URL is undefined");
    }
  };
  const sanitizeSkillName = (name) => {
    // Replace special characters with spaces
    const sanitizedName = name.replace(/[^\w\s]/gi, ' ');

    // Trim extra spaces from the beginning and end of the string
    return sanitizedName.trim();
  }

  return (
    <>

      <div className="footer">
        <div className="footer-inner-container">


          <div className="footer-container">
            <div className="footer-logo">
              {/* <img src="/image/footerLogo.png" alt="" /> */}
              <h5>Join Our Newsletter</h5>
              <p>Be the first to know about our latest updates, exclusive offers, and more.</p>

            </div>
            <div className="footer-subscribe">

              <form onSubmit={submitSubscribe} action="">
                <div className='subscribe-input'><input required type="email" placeholder='Enter your email address' value={subscribeValues?.email} name="email" onChange={(e) => setSubscribeValues({ ...subscribeValues, [e.target.name]: e.target.value })} id="" /></div>
                <div className='subscribe-button'><button type='submit' className='btn'>Subscribe</button></div>
              </form>



            </div>
          </div>


          <div className="footer-category">
            <div className='category-heading'><h5><ContentCopyIcon /> Categories</h5></div>
            <div className='categories-data'>
              {categoriesData?.map((item, index) => (
                <React.Fragment key={item.id}>
                  <div className='categories-skill'>
                    {recuiterId ? (
                      <Link to={`/Serach-Freelancer/industry/${item?.name}`}>
                        {sanitizeSkillName(item?.name)}
                      </Link>
                    ) : client_Id ? (
                      <Link to={`/Search-recuiter/skill/${item?.name}`}>
                        {sanitizeSkillName(item?.name)}
                      </Link>
                    ) : (
                      <Link to={`/Serach-Freelancer/industry/${item?.name}`}>
                        {sanitizeSkillName(item?.name)}
                      </Link>
                    )}
                  </div>
                  {index < categoriesData.length - 1 && <span> | </span>}
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="footer-location">
            <div className='location-heading'><h5><LocationOnIcon /> Locations</h5></div>
            <div className="footer-choose-location">
              <label for="inputPassword4" className="form-label">Choose your country </label>
              <select
                onChange={handleInputChange}

                //  onChange={(e) => setformValues({ ...formValues, [e.target.name]: e.target.value })}
                name='country_name' value={formValues?.country_name} style={{ boxShadow: "none", }} className="form-select" aria-label="Default select example">
                <option value={''} selected>Select Country</option>

                {countries && countries?.map((item) => {
                  return (

                    <option value={item?.country_name} style={{ textTransform: "capitalize" }}  >{item?.country_name}</option>


                  )
                })}

              </select>
            </div>
            <div className='location-data'>


              {countryData.map((item, index, array) => (
                <React.Fragment key={item.id}>
                  {client_Id ? (
                    <Link className='location-city' to={`/Search-recuiter/location/${item?.city_name}`}>
                      {sanitizeSkillName(item?.city_name)}
                    </Link>
                  ) : (
                    <Link className='location-city' to={`/Serach-Freelancer/location/${item?.city_name}`}>
                      {sanitizeSkillName(item?.city_name)}
                    </Link>
                  )}
                  {index < array.length - 1 && <span>|</span>}
                </React.Fragment>
              ))}

            </div>
          </div>
          <div className="footer-about-section">
            <div className='footer-about-links1'>
              <h5>Useful Links</h5>
              <p><Link  to='https://nearbyfreelancers.com/about-us'  target='_blank'>About us</Link></p>
              {/* <p><Link to='/'>How it works</Link></p> */}
              <p><Link to='https://nearbyfreelancers.com/blogs' target='_blank'>Blog</Link></p>
              <p><Link to='/refer-login'>Refer / Influencer Club / WFH</Link></p>
              <p><Link to='https://nearbyfreelancers.com/contact-us' target='_blank'>Contact Us</Link></p>
              <p className='footer-email'>support@nearbyfreelancers.com</p>
              <h5>Details</h5>
              <p style={{ cursor: "pointer" }} onClick={handleOpenModal} className='footer-email'>Download Brochure</p>
       

            </div>
            <div className='footer-about-links2'>
              <h5>Terms</h5>
              <p><Link to='/privacy-and-policy'>Privacy Policy</Link></p>
              <p><Link to='/term-and-condition'>Terms of Service</Link></p>
              {/* <p><Link to='/ '>Fees and Charges</Link></p> */}
              {/* <p><Link to='/ '>User Agreement</Link></p> */}
            </div>
            <div className='footer-about-links2'>
              <h5>Subscription Plan</h5>
              <p>
                <Link to="/premium-non-it">Premium Category Pricing Plan - India (Non-IT)</Link>
              </p>
              <p>
                <Link to="/pricing-plan-it">Price Plan - India (IT)</Link>
              </p>
              <p>
                <Link to="/credit-points-non-it">Price Plan - India (Non-IT)</Link>
              </p>

              <p>
                <Link to="/credit-points">Price Plan - Global Countries</Link>
              </p>



            </div>
            <div className='footer-about-links3'>
              <h5>For Freelancer</h5>
              <p>
                {!client_Id ?
                  <Link to='/register-as-freelancer'>Find Project</Link> :
                  <Link to='/view-all-projects'>Find Project</Link>
                }

              </p>
              <p>
                <Link to='/register-as-freelancer'>Freelancer Login</Link>
              </p>

            </div>
            <div className='footer-about-links4'>
              <h5>For Recuiter</h5>
              <p>
                {recuiterId ?
                  <Link to='/ '>Hire Freelancer</Link> :
                  <Link to='/job-recuiter-login'>Hire Freelancer</Link>
                }

              </p>
              <p><Link to='/job-recuiter-login'>Client Login</Link></p>

            </div>
            {/* <div className='footer-about-links5'>
              <h5>Contact Us</h5>
              <p className='footer-email'>
                Cabin No.6G, 4th Floor, JMD Regent Arcade Mall, A-Block, DLF Phase-1, Sector-28, MG Road, Gurugram, Haryana, 122002
                Gurugram
              </p>

            


              <p><a href='tel:9625318281'>9625318281</a></p>
              <h3>Headquarter</h3>
              <p> Gurugram</p>


            </div> */}
          </div>
          <div className="footer-social-links">
            <div className="social-links-flex1"> <Link to='/'><img src="/svg/nbffooter.svg" alt="" /></Link></div>
            <div className="social-links-flex2"><h6>© 2024 Nearby Freelancers. All Rights Reserved.</h6></div>
            <div className="social-links-flex3">
              <Link target='_blank' to="https://www.facebook.com/nearbyfreelancer">
                <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faFacebookF} style={{ borderRadius: '50%', background: 'white', color: "black", width: '25px', height: '25px', fontSize: '25px' }} />
              </Link>

              <Link target='_blank' to="https://www.linkedin.com/company/nearbyfreelancers/">
                <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faLinkedinIn} style={{ borderRadius: '50%', background: 'white', color: "black", width: '25px', height: '25px', fontSize: '25px' }} />

              </Link>
              <Link target='_blank' to="https://www.instagram.com/nearbyfreelancers?igsh=MWg0cGhkZmliYWY4eQ==">
                <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faInstagram} style={{ borderRadius: '50%', background: 'white', color: "black", width: '25px', height: '25px', fontSize: '25px' }} />

              </Link>
              <Link target='_blank' to="https://www.youtube.com/@nearbyfreelancers">
                <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faYoutube} style={{ borderRadius: '50%', background: 'white', color: "black", width: '25px', height: '25px', fontSize: '25px' }} />

              </Link>

              {/* <FontAwesomeIcon className='py-2 px-2 ms-2' icon={faTwitter} style={{ borderRadius: '50%', background: 'white', color: "#3C486B", width: '30px', height: '30px', fontSize: '30px' }} /> */}
            </div>
          </div>
        </div>
        <BrochureModel
          pdf={() => handlePDF(PDF_FILE)}
          show={showModal}
          handleClose={handleCloseModal}
        />
        <ToastContainer />

      </div>
    </>
  )
}

export default Footer

