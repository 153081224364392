import React, { useEffect, useState } from 'react'
import Footer from '../../Layout/Footer/Index'
import Header from '../../Layout/Header/Index';
import "./viewprojectdetails.scss"
import { axiosApi } from '../../_helper/api_helper';
import { Button } from 'react-bootstrap';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingPannel from '../../commonComponent/LoadingPannel/LoadingPannel';


const ViewAllProjectDetail = () => {
  const navigate = useNavigate();
  const freelancerId = localStorage.getItem('client_Id')
  const { projectid, recuiterid } = useParams();
  const [projectbyId, setProjectById] = useState({});
  const [recentProject, setRecentProject] = useState([]);
  const [freelancerSkills, setFreelancerSkills] = useState([]);
  const [skillMatchCount, setSkillMatchCount] = useState(0);
  const [loading, setLoading] = useState(false);
  
  
  const handleNavigateProfile=(singleId)=>{
    
    navigate(`/Freelancer/${singleId}`);
  }
  useEffect(() => {
    recentprojectApi();
    
    if (freelancerId) fetchFreelancerData();
  }, [freelancerId]);
  
  useEffect(() => {
    if ( projectid) {
      fetchDatapi();
    }
  }, [ projectid]);


  const fetchFreelancerData = async () => {
    try {
      const result = await axiosApi.get(`clientdata/${freelancerId}`);
      const skills = result?.data?.software?.map((skill) => skill.name) || [];
      setFreelancerSkills(skills);
       
    } catch (error) {
      console.error("Error fetching freelancer data:", error);
    }
  };
  const recentprojectApi = async () => {
    try {
      const response = await axiosApi.get('/multijobs/all');
      setRecentProject(response.data);
    } catch (error) {


    }
  }



 
    const fetchDatapi = async () => {
      try {
        setLoading(true)
        const response = await axiosApi.get(`/multijob/${recuiterid}/${projectid}`);
        setProjectById(response?.data);
        // const projectSkills = response?.data?.skills?.split("\t") || [];
        const projectSkills = response?.data?.skills?.map((skill) => skill.name.trim().toLowerCase()) || [];
        console.log("projectSkills", projectSkills);
        const normalizedFreelancerSkills = freelancerSkills.map((skill) => skill.toLowerCase());
        const matches = projectSkills.filter((skill) => normalizedFreelancerSkills.includes(skill));
           
        
        setSkillMatchCount(matches);
         
        setLoading(false);
      } catch (error) {
        // console.error("Error fetching recruiter details:", error);
        setLoading(false);
      }
    };
    // fetchData()
    // if (projectid) {
    //   fetchData();
    // }
    const handleBidClick = () => {
      const freelancerId = localStorage.getItem("client_Id");
  
      if (!freelancerId) {
        navigate("/register-as-freelancer"); // Redirect if not registered
      } else {
        navigate(`/bid-on-project/${projectbyId?.client}/${projectbyId?.id}`);
      }
    };

      useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      }, [])


  const calculateTimeElapsed = (createdAt) => {
    const currentDate = new Date();
    const postedDate = new Date(createdAt);
    const timeDifference = currentDate - postedDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return `${years} ${years === 1 ? 'year' : 'years'} ago`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? 'month' : 'months'} ago`;
    } else if (weeks > 0) {
      return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
    }
  };
  return (
    <>
      <Header />
      {loading && <LoadingPannel />}
 
    
      {/* <div className="view-details-image">
        <img src="/image/project-detail.png" alt="" />
      </div> */}
      <div className="view-project-details">
        <div className="view-project-details-container">
          <div className="view-project-detail-flex1">
            <div className="view-project-detail-flex1-container">
              <div className="view-details-heading">
                <h3>{projectbyId?.project_title?.replace(/^"|"$/g, '')}</h3>
                <h3>{projectbyId.min_perhour_usd || projectbyId.min_fixedprice_usd && (
                  <span className='' style={{ color: "green", fontWeight: "600", padding: "8px", borderRadius: "5px" }}>
                    US$ {projectbyId.min_perhour_usd && projectbyId.max_perhour_usd
                      ? `${projectbyId.min_perhour_usd} - ${projectbyId.max_perhour_usd}`
                      : projectbyId.min_fixedprice_usd && projectbyId.max_fixedprice_usd
                        ? `${projectbyId.min_fixedprice_usd} - ${projectbyId.max_fixedprice_usd}`
                        : 'null'}
                  </span>
                )}</h3>
              </div>
              <div className="view-details-dated">
                <h6>Posted {calculateTimeElapsed(projectbyId?.created_at)}</h6>
              </div>
              <div className="view-details-skill">
                <h6>Skill Required</h6>


                {projectbyId?.skills ? (
                  <span className='' style={{ color: "black", fontWeight: "500", display: "flex", flexWrap: "wrap", gap: "5px" }}>
                    {projectbyId.skills.map((skill, index) => (
                      <button key={index} className='btn btn-light'>{skill?.name}</button>
                    ))}
                  </span>
                ) : (
                  <span>No skills specified</span>
                )}

              </div>
              <div className="view-details-skill">
                <h6>Industry Required </h6>
                <div> <button style={{ background: "#F9E8C9", padding: "0.5rem", textTransform: "capitalize" }} className='btn btn-light'>{projectbyId?.industry}</button></div>
              </div>
              <div className="view-details-para">
                <pre style={{ whiteSpace: "pre-wrap", fontFamily: `"Poppins", sans-serif` }}
                  dangerouslySetInnerHTML={{
                    __html: projectbyId?.project_description?.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>"),
                  }}
                >
                  {/* {recuiterById?.project_description} */}
                </pre>
              </div>
              <div className="view-details-skill">
                 
                <div> <button style={{ background: "rgb(232,232,232)", padding: "0.5rem", textTransform: "capitalize" }} className='btn btn-light'>{skillMatchCount?.length} Skills Match</button></div>
              </div>
              <div className="plaace-your-bid-button">
              <Button 
                  onClick={handleBidClick}
                variant="" style={{ background: '#FF3131', fontWeight: "500", color: "white" }} >Place your Bid </Button>
              </div>
              {/* <Link to={`/bid-on-project/${projectbyId?.client}/${projectbyId?.id} `}> */}
           
              {/* </Link> */}
            </div>
            <h4>{projectbyId?.booking_interest_by_count} freelancers are bidding on this project</h4>
            <div className="recentbidfreelancer">
              {projectbyId?.freelancer_details?.map((item) => (

                <div onClick={()=>handleNavigateProfile(item?.freelancer_id)} className="allrecuiter-content" key={item.project_id}>
                  <div className='bidfreelancer-image'>
                    <img src={`${axiosApi?.defaults?.baseURL}${item?.freelancer_profile_pic}`} alt="" />
                  </div>
                  <div className='view-details-title'>
                    <h5>{item?.freelancer_name}</h5>
                    <h4 className='job-hostedby'><BusinessCenterIcon /> {item?.occupation_industry}</h4>
                    <p>{item?.cover_letter?.slice(0, 100)}...</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="view-project-detail-flex2">
            <div className="project-cards">
              <h5>Similar Project</h5>
              {recentProject?.splice(0, 10).map((item) => (
                <div
                 className="allrecuiter-content"
                 onClick={() => navigate(`/project-details/${item.project_id}/${item.recruiter_id}`)}
                 style={{ cursor: 'pointer' }}
                 key={item.project_id}>
                
                  <div className='job-title'>
                    <h5>{item?.project_title?.replace(/^"|"$/g, '')}</h5>

                  </div>

                  <h4 className='job-hostedby'>Hosted by {item?.recruiter_organisation_name || item?.recruiter_name}</h4>
                  <h4 className='job-category'  >{item?.project_occupation_skill || item?.occupation_skill}</h4>
                   

                  <div className="applicant-education-price"> US$ {item?.min_perhour_usd && item?.max_perhour_usd
                    ? `${item.min_perhour_usd} - ${item.max_perhour_usd} Per/Hour`
                    : item?.min_fixedprice_usd && item?.max_fixedprice_usd
                      ? `${item.min_fixedprice_usd} - ${item.max_fixedprice_usd} Fix Price`
                      : 'N/A'}
                  </div>

                  <div className='hiretalent-content-about'>{item?.project_description?.slice(0, 100)}...</div>



                </div>
              ))}
            </div>

          </div>


        </div>
      </div>


      <Footer />

    </>
  )
}

export default ViewAllProjectDetail
