import React, { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { PublicRoute, RoleBasedRoute } from './RoleBasedRoutes';
// import JobPost from '../components/FindRecuriter/component/JobPost';
 
import GlobalProjectCompleted from '../components/ProjectPostwihtoutLogin/GlobalProjectCompleted';
import ProjectPostLogin from '../components/projectpostwithlogin/ProjectPostLogin';
import JobPostwithoutLogin from '../components/JobPostingWithoutLogin/JobPostwithoutLogin';
import JobPostLogin from '../components/JobPostingWithoutLogin/JobPostLogin';
import GlobaljobSubmiitted from '../components/JobPostingWithoutLogin/GlobaljobSubmiitted';
import OtherCountries from '../authentication/registration/OtherCountries';
import ViewAllProjectDetail from '../components/FindRecuriter/ViewAllProjectDetail';
import BidOnProject from '../pages/bidonproject/BidOnProject';
import ITPriceplan from '../components/PricingPlan/ITPriceplan';
import PremimumNonIt from '../components/PaymentGateway/PremimumNonIt';
 

const Home = lazy(() => import('../components/Home/Index'));
const ApplicantForJob = lazy(() => import('../components/FindRecuriter/commoncomponent/ApplicantForJob')); 
const ReferEarn = lazy(() => import('../components/refertoearnnew/ReferEarn')); 
const SignUpReferalNew = lazy(() => import('../components/refertoearnnew/SignUpReferalNew')); 
const PostWorkRecuiter = lazy(() => import('../components/FindRecuriter/component/PostWorkRecuiter')); 
const VerifyEmail = lazy(() => import('../components/refertoearnnew/VerifyEmail'));
const ForgotPassword = lazy(() => import('../components/refertoearnnew/ForgotPassword'));
const ReferDashboard = lazy(() => import('../components/ReferClub/ReferDashboard'));
const ProjectPost = lazy(() => import('../components/ProjectPostwihtoutLogin/ProjectPost'));
const TaxInvoice = lazy(() => import('../components/Taxinvoice/TaxInvoice'));
const TesterPaymentNonit = lazy(() => import('../components/ReferToEarn/TesterPaymentNonit'));
const TesterPayment = lazy(() => import('../components/ReferToEarn/TesterPayment'));
const PaymentGateway = lazy(() => import('../components/PaymentOutside/PaymentGateway'));
const NonItPayment = lazy(() => import('../components/PaymentGateway/NonItPayment'));
const AllFreelancers = lazy(() => import('../components/FindFreeLancers/componenets/AllFreelancers'));
const ViewAllProjects = lazy(() => import('../components/FindRecuriter/ViewAllProjects'));




const NotFound = lazy(() => import('../components/NotFound/NotFound'));
const Registration = lazy(() => import('../authentication/registration/Registration'));
const Login = lazy(() => import('../authentication/login/Login'));
const Blog = lazy(() => import('../components/Blog/Blog'));
const BlogsById = lazy(() => import('../components/Home/compoenet/ourBlog/BlogsById'));
const About = lazy(() => import('../components/About/About'));
const Contact = lazy(() => import('../components/Contact/Index'));
const PrivacyandPolicy = lazy(() => import('../components/privacyandpolicy/PrivacyandPolicy'));
const TermsAndAcondtion = lazy(() => import("../components/termandcondition/TermsAndAcondtion"));
const ReferToEarn = lazy(() => import('../components/ReferToEarn/ReferToEarn'));
const BuyPoints = lazy(() => import('../components/ReferToEarn/BuyPoints'));
const SingleProfile = lazy(() => import('../components/FindFreeLancers/componenets/SingleProfile'));
const FindFreeLancer = lazy(() => import('../components/FindFreeLancers/Index'));
const FreelancerDashboardIndex = lazy(() => import('../components/freelancerDashboardProfile/FreelancerDashboardIndex'));
const NewLoginForm = lazy(() => import('../components/LoginProfile/NewLoginForm'));
const ProfileCompleted = lazy(() => import('../components/LoginProfile/compoenets/ProfileCompleted'));
const FindRecuiter = lazy(() => import('../components/FindRecuriter/Index'));
const AllRecuiter = lazy(() => import('../components/FindRecuriter/AllRecuiter'));
const Interested = lazy(() => import('../components/FindRecuriter/Interested'));
const HireTalentJob = lazy(() => import('../components/hiretalentjob/HireTalentJob'));
const NewRecuriterForm = lazy(() => import('../components/JobRecuriter/NewRecuriterForm'));
const RecuiterProfile = lazy(() => import('../components/FindRecuriter/RecuiterProfile'));
const SuccessFreelancer = lazy(() => import('../components/FindFreeLancers/componenets/SuccessFreelancer'));
const ViewAllFreelancer = lazy(() => import('../components/FindFreeLancers/componenets/ViewAllFreelancer'));
const RecuiterProfileCompleted = lazy(() => import('../components/JobRecuriter/components/RecuiterProfileCompleted'));
const SuccessJob = lazy(() => import('../components/hiretalentjob/SuccessJob'));
const Resume = lazy(() => import('../components/FindFreeLancers/componenets/Resume'));
const Texting = lazy(() => import('../Layout/Header/Texting'));
const ApplicantForProject = lazy(() => import('../components/FindRecuriter/commoncomponent/ApplicantForProject'));
const RecuiterCredits = lazy(() => import('../components/recuiterPaymentlink/RecuiterCredits'));
const LoadingPannel = lazy(() => import('../commonComponent/LoadingPannel/LoadingPannel'));

    
 
 const LayoutRoutes =React.memo( () => {
  return(
 

<Routes>
  {/* Public routes */}
  <Route path="/" element={<Home />} />
  <Route path="/Freelancer/:id" element={<SingleProfile />} />
  <Route path="/resume/:id" element={<Resume />} />
  <Route path="/Serach-Freelancer/location/:city" element={<FindFreeLancer />} />
  <Route path="/Serach-Freelancer/industry/:skill" element={<FindFreeLancer />} />
  <Route path="/register-as-freelancer" element={<Registration />} />
  <Route path="/login-as-freelancer" element={<OtherCountries />} />

  <Route path="/job-recuiter-login" element={<Login />} />
  <Route path="/credits" element={<BuyPoints />} />
  <Route path="/pricing-plan-it" element={<ITPriceplan />} />
  <Route path="/recuiter-profile-completed" element={<RecuiterProfileCompleted />} />
  <Route path="/refer-login" element={<ReferEarn />} />
  <Route path="/refer-to-earn-signup" element={<SignUpReferalNew />} />
  {/* <Route path="/success-payment" element={<NotFound />} /> */}
  <Route path="/view-all-projects" element={<ViewAllProjects />} />
  <Route path="/project-details/:projectid/:recuiterid" element={<ViewAllProjectDetail />} />
  <Route path="/hire-talent" element={<HireTalentJob />} />
  <Route path="/all-freelancer" element={<AllFreelancers />} />
  <Route path="/view-all-freelancer/:category/:subcategory" element={<ViewAllFreelancer />} />
  <Route path="/view-all-freelancer/:category" element={<ViewAllFreelancer />} />

  <Route path="/hire-talent/:category/:subcategory" element={<HireTalentJob />} />
  <Route path="/all-projects/:category/:subcategory" element={<AllRecuiter />} />
  <Route path="/credits-plan" element={<RecuiterCredits />} />
  <Route path="/blogs" element={<Blog />} />
  {/* <Route path="/blog/:id" element={<BlogsById />} /> */}
  {/* <Route path="/about-us" element={<About />} />
  <Route path="/contact-us" element={<Contact />} /> */}
  <Route path="/term-and-condition/*" element={<TermsAndAcondtion />} />
  <Route path="/privacy-and-policy" element={<PrivacyandPolicy />} />
  {/* <Route path="/refer-to-earn" element={<ReferToEarn />} /> */}
  <Route path="/verify-email/:token" element={<VerifyEmail />} />
  <Route path="/forgot-password/:token" element={<ForgotPassword />} />
  <Route path="/refer-dashboard/*" element={<ReferDashboard />} />
  <Route path="/project-post" element={<ProjectPost />} />
  <Route path="/job-post" element={<JobPostwithoutLogin />} />
  <Route path="/tax-invoice" element={<TaxInvoice />} />
  <Route path="/credits-non-it-tester" element={<TesterPaymentNonit />} />
  <Route path="/credits-it-tester" element={<TesterPayment />} />
  <Route path="/bid-on-project/:recruiterid/:projectid" element={<BidOnProject />} />
  <Route path="/credit-points" element={<PaymentGateway />} />
  <Route path="/credit-points-non-it" element={<NonItPayment />} />
  <Route path="/premium-non-it" element={<PremimumNonIt />} />
  <Route path="/recuiter-profile/*" element={<RecuiterProfile />} >
    <Route path="post_a_project" element={<PostWorkRecuiter />} />

  </Route>
  {/* Client routes */}
  <Route element={<RoleBasedRoute allowedRoles={['client']} />}>
    <Route path="/job-recuiter-login" element={<Login />} />
    <Route path="/Profile" element={<NewLoginForm />} />
    <Route path="/Profile/professional-detail" element={<NewLoginForm />} />
    <Route path="/Profile/portfolio" element={<NewLoginForm />} />
    <Route path="/Profile/payment-details-security" element={<NewLoginForm />} />
    <Route path="/profile-completed" element={<ProfileCompleted />} />
    <Route path="/Search-recuiter/skill/:skill" element={<FindRecuiter />} />
    <Route path="/Search-recuiter/location/:city" element={<FindRecuiter />} />
    <Route path="/all-projects" element={<AllRecuiter />} />
    <Route path="/all-projects/:name" element={<AllRecuiter />} />
    <Route path="/Success/:id" element={<Interested />} />
    <Route path="/Success-job/:id" element={<SuccessJob />} />
    <Route path="/hire-talent" element={<HireTalentJob />} />
    <Route path="/hire-talent/:name" element={<HireTalentJob />} />
    {/* <Route path="/success-payment" element={<SuucessPayment />} /> */}
    {/* <Route path="/recuiter-profile-completed" element={<RecuiterProfileCompleted />} /> */}
    <Route path="/freelancer-dashboard/*" element={<FreelancerDashboardIndex />} />
    <Route path="/refer-to-earn" element={<ReferToEarn />} />
  <Route path="/pricing-plan-it" element={<ITPriceplan />} />

    <Route path="/credits" element={<BuyPoints />} />
   
    <Route path="/texting" element={<Texting />} />

  </Route>





  {/* Recruiter routes */}

  <Route element={<RoleBasedRoute allowedRoles={['recruiter']} />}>
    <Route path="/register-as-freelancer" element={<Registration />} />
    <Route path="/Freelancer/:id" element={<SingleProfile />} />
    <Route path="/Serach-Freelancer/location/:city" element={<FindFreeLancer />} />
  <Route path="/Serach-Freelancer/industry/:skill" element={<FindFreeLancer />} />

    {/* <Route path="/Serach-Freelancer/industry/:skill" element={<FindFreeLancer />} /> */}
    <Route path="/job-recuiter-login" element={<Login />} />
    <Route path="/HireFreeLancer/personal-details" element={<NewRecuriterForm />} />
    <Route path="/HireFreeLancer/picks" element={<NewRecuriterForm />} />
    <Route path="/HireFreeLancer/job-post" element={<NewRecuriterForm />} />
    <Route path="/HireFreeLancer/project-post" element={<NewRecuriterForm />} />
    <Route path="/project-posting" element={<ProjectPostLogin />} />
    <Route path="/job-posting" element={<JobPostLogin />} />
    <Route path="/recuiter-profile/*" element={<RecuiterProfile />} />
    <Route path="/applicants-regaring-project/:id" element={<ApplicantForProject />} />
    <Route path="/applicants-regaring-by-project/:name" element={<ApplicantForProject />} />
    <Route path="/applicants-regarding-job/:id" element={<ApplicantForJob />} />
    <Route path="/SuccessFreelancer/:name" element={<SuccessFreelancer />} />
    <Route path="/recuiter-profile-completed" element={<RecuiterProfileCompleted />} />
    <Route path="/project-submitted" element={<GlobalProjectCompleted />} />
    <Route path="/job-submitted" element={<GlobaljobSubmiitted />} />
    <Route path="/all-freelancer" element={<AllFreelancers />} />
    <Route path="/credits-plan" element={<RecuiterCredits />} />
  </Route>


  {/* Catch-all */}
  <Route path="*" element={<NotFound />} />
</Routes>
 
  )
 



   
 
 })
 
 export default LayoutRoutes
 