import React, { useEffect, useState } from 'react';
import "./bidonproject.scss"
import Header from '../../Layout/Header/Index';
import Footer from '../../Layout/Footer/Index';
import { axiosApi } from '../../_helper/api_helper';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import LoadingPannel from '../../commonComponent/LoadingPannel/LoadingPannel';
const BidOnProject = () => {
    const { recruiterid, projectid } = useParams();
    const freelacnerId = localStorage.getItem("client_Id")
    const [paymentMethod, setPaymentMethod] = useState("milestone");
    const [milestones, setMilestones] = useState([
        { description: "", dueDate: "", amount: "" },
    ]);
    const [formValues, setFormValues] = useState({
        bid_amount: "",

        project_duration: "",
        cover_letter: "",
        milestones: [],
    });
    const [bidAmount, setBidAmount] = useState('');
    const [serviceFee, setServiceFee] = useState(0);
    const [receivedAmount, setReceivedAmount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [servieMile, setServiceMile] = useState(0);
    const [receiveMile, setReceiveMile] = useState(0)
    const [milestoneErrors, setMilestoneErrors] = useState({});
    const [loading, setLoading] = useState(false);
  const [projectbyId, setProjectById] = useState({});

  useEffect(() => {
    if ( projectid) {
      fetchDatapi();
    }
  }, [ projectid]);
    const handleFormchange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }
    const handleBidAmountChange = (value) => {
        const amount = parseFloat(value) || 0;
        const fee = amount * 0.08; // 8% service fee
        const received = amount - fee;

        setBidAmount(amount > 0 ? amount : ""); // Show empty if value is 0
        setServiceFee(amount > 0 ? fee.toFixed(2) : ""); // Format to 2 decimal places
        setReceivedAmount(amount > 0 ? received.toFixed(2) : "");
    };

    const handleAddMilestone = () => {
        setMilestones([...milestones, { description: "", dueDate: "", amount: "" }]);
    };
    const handleInputChange = (index, field, value) => {
        const updatedMilestones = [...milestones];
        updatedMilestones[index][field] = value;

        // Validate the amount for milestones
        if (field === "amount") {
            const amount = parseFloat(value) || 0;

            if (amount < 5 && value !== "") {
                // Add error if amount is less than $5
                setMilestoneErrors((prevErrors) => ({
                    ...prevErrors,
                    [index]: "Amount must be at least $5",
                }));
            } else {
                // Clear the error if amount is valid
                setMilestoneErrors((prevErrors) => {
                    const newErrors = { ...prevErrors };
                    delete newErrors[index];
                    return newErrors;
                });
            }

            updatedMilestones[index][field] = amount || "";
            calculateTotals(updatedMilestones);
        }

        setMilestones(updatedMilestones);
    };
    const handleRemoveMilestone = (index) => {
        const updatedMilestones = milestones.filter((_, i) => i !== index);
        calculateTotals(updatedMilestones);
        setMilestones(updatedMilestones);
    };
 const fetchDatapi = async () => {
      try {
        setLoading(true)
        const response = await axiosApi.get(`/multijob/${recruiterid}/${projectid}`);
        setProjectById(response?.data);
        
        
        setLoading(false);
      } catch (error) {
        console.error("Error fetching recruiter details:", error);
        setLoading(false);
      }
    };

    const calculateTotals = (updatedMilestones) => {
        const total = updatedMilestones.reduce((acc, milestone) => acc + (parseFloat(milestone.amount) || 0), 0);
        const fee = total * 0.08; // 8% fee
        const received = total - fee;

        setTotalPrice(total.toFixed(2));
        setServiceMile(total > 0 ? fee.toFixed(2) : 0);
        setReceiveMile(total > 0 ? received.toFixed(2) : 0);
    };

    const handleGenerateCoverLetter = async () => {
    
        try {
            if(!formValues?.cover_letter){
                toast.error("Please enter few points ", { position: "top-left" });
            return;
            }
            setLoading(true)
            const payload = {
                project_details: formValues?.cover_letter, // You can use this as input for the AI
            };
            const result = await axiosApi.post("generate-coverletter", payload);
           
            
            if (result.data && result?.data?.cover_letter) {
                setFormValues((prevValues) => ({
                    ...prevValues,
                    cover_letter: result?.data?.cover_letter,
                }));
                toast.success("Cover letter generated successfully!", {
                    position: "top-left",
                });
                setLoading(false)
            } else {
                toast.error("Failed to generate cover letter.", {
                    position: "top-left",
                });
                setLoading(false)
            }
        } catch (error) {
            toast.error("Error generating cover letter. Please try again.", {
                position: "top-left",
            });
            setLoading(false)
        }
    };

    const submitPropsal = async () => {
        let errorMessage = "";
        if (paymentMethod === "project" && !bidAmount) {
            errorMessage = "Please fill the bid amount for the project.";
        }else if (paymentMethod === "milestone" && !totalPrice) {
            errorMessage = "Please fill the bid amount.";
        }
        
         else if (!formValues?.project_duration) {
            errorMessage = "Please select project duration.";
        } else if (!formValues?.cover_letter) {
            errorMessage = "Please fill the cover letter.";
        }


        if (errorMessage) {
            // Only show one error message at a time
            toast.error(errorMessage, { position: "top-left" });
            return;
        }
        try {
            const bidAmountValue = paymentMethod === "milestone" ? totalPrice  : bidAmount;

            const payload = {
                freelancer_id: freelacnerId,
                recruiter_id: recruiterid,
                client_id: freelacnerId,
                multi_job_id: projectid,
                bid_amount: bidAmountValue, // Replace with actual bid amount
                payment_choice: paymentMethod,
                project_duration: formValues?.project_duration,
                cover_letter: formValues?.cover_letter, // Replace with the cover letter value
            };


            // Add milestones only if the payment method is "milestone"
            if (paymentMethod === "milestone") {
                payload.milestones = milestones.map((milestone) => ({
                    description: milestone.description,
                    due_date: milestone.dueDate,
                    amount: milestone.amount,
                }));
            }

            const result = await axiosApi.post('/freelancer-booking-recuiterjob', payload);
            toast.success("Proposal sent successfully!", { position: "top-left" });
        } catch (error) {
            console.log("Errorchecking",error)
            toast.error(error?.response?.data?.error, { position: "top-left" });
        }
    }
  useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      }, [])
    return (
        <>
            <Header />
            {loading && <LoadingPannel/>}
            <div className="bidonproject">
                <div className="bidonproject-container">
                    <div className="propsal-setting">

                        <h3>Proposal settings <br /> <span>
                            This proposal require 15 credits
                        </span></h3>

                        <h3>{projectbyId.min_perhour_usd || projectbyId.min_fixedprice_usd && (
                  <span className=''  >
                    US$ {projectbyId.min_perhour_usd && projectbyId.max_perhour_usd
                      ? `${projectbyId.min_perhour_usd} - ${projectbyId.max_perhour_usd}`
                      : projectbyId.min_fixedprice_usd && projectbyId.max_fixedprice_usd
                        ? `${projectbyId.min_fixedprice_usd} - ${projectbyId.max_fixedprice_usd}`
                        : 'null'}
                  </span>
                )} <br /> </h3>
                    </div>
                    <div className="how-paid">
                        <h5>How do you want to be paid?</h5>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="paymentMethod"
                                id="milestone"
                                checked={paymentMethod === "milestone"}
                                onChange={() => setPaymentMethod("milestone")}
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                By milestone
                            </label>
                            <p>
                                Divide the project into smaller segments, called milestones. You'll be paid for milestones as they are completed and approved.
                            </p>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="paymentMethod"
                                id="project"
                                checked={paymentMethod === "project"}
                                onChange={() => setPaymentMethod("project")}
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                By Project
                            </label>
                            <p>
                                Get your entire payment at the end. when all work has been delevired.
                            </p>
                        </div>
                    </div>
                    {paymentMethod === "milestone" && (
                        <>
                            <div className="mile-stone-include">
                                <h5>How many milestones do you want to include?</h5>
                                {milestones.map((milestone, index) => (
                                    <div key={index} className="milestone-row">
                                        <input
                                            type="text"
                                            placeholder="Description"
                                            className="description-input"
                                            value={milestone.description}
                                            onChange={(e) =>
                                                handleInputChange(index, "description", e.target.value)
                                            }
                                        />
                                        <input
                                            type="date"
                                            placeholder="Due Date"
                                            className="date-input"
                                            value={milestone.dueDate}
                                            onChange={(e) =>
                                                handleInputChange(index, "dueDate", e.target.value)
                                            }
                                        />

                                        <input
                                            type="number"
                                            placeholder="Amount"
                                            className={`amount-input ${milestoneErrors[index] ? "error" : ""}`}
                                            value={milestone.amount}
                                            onChange={(e) =>
                                                handleInputChange(index, "amount", e.target.value)
                                            }
                                        />
                                        {milestoneErrors[index] && (
                                            <span className="error-message">{milestoneErrors[index]}</span>
                                        )}

                                        {index !== 0 && (
                                            <button
                                                className="remove-milestone-button"
                                                onClick={() => handleRemoveMilestone(index)}
                                            >
                                                ✖
                                            </button>
                                        )}
                                    </div>
                                ))}
                                <button onClick={handleAddMilestone} className="add-milestone-button">
                                    + Add Milestone
                                </button>

                            </div>

                            <div className="total-price">
                                <div className="total-price-inner">
                                    <h6>Total Price of project <br />
                                        <span>This include all milestones, and is the amount your client will see</span>
                                    </h6>
                                    <h6>{totalPrice > 0 ? `$${totalPrice}` : "$0.00"}</h6>
                                </div>
                                <div className="total-price-inner">
                                    <h6>8% Freelancer Service Fee</h6>
                                    <h6>
                                        {servieMile > 0 ? `$${servieMile}` : "$0.00"}
                                    </h6>
                                </div>
                                <div className="total-price-inner">
                                    <h6>You'll Receive</h6>
                                    <h6>
                                        {receiveMile > 0 ? `$${receiveMile}` : "$0.00"}
                                    </h6>
                                </div>
                            </div>
                        </>

                    )}
                    {paymentMethod === "project" && (
                        <div className="bid-for-project">
                            <h5>What is the full amount you'd like to bid for this project</h5>
                            <div className="bid-for-project-inner">
                                <h6>Bid <br /> <span>Total amount the client will see on your proposal</span> </h6>
                                <input type="number"
                                    value={bidAmount}
                                    onChange={(e) => handleBidAmountChange(e.target.value)}
                                    placeholder={bidAmount ? `$+${bidAmount}` : "Enter amount"}
                                />


                            </div>
                            <div className="bid-for-project-inner">
                                <h6>8% Freelancer Service Fee</h6>
                                <h6>{serviceFee > 0 ? `$${serviceFee}` : "$0.00"}</h6>
                                {/* <input type="number"
                                    value={serviceFee}
                                    readOnly
                                    disabled
                                    placeholder="Service fee" /> */}
                            </div>
                            <div className="bid-for-project-inner">
                                <h6>You'll Receive <br /> <span>The estimated amount you'll receive after service fees</span> </h6>
                                <h6>{receivedAmount > 0 ? `$${receivedAmount}` : "$0.00"}</h6>

                                {/* <input type="number"
                                    value={receivedAmount}
                                    readOnly
                                    placeholder="Receive amount" /> */}
                            </div>
                        </div>
                    )}
                    <div className="take-project">
                        <h5>How long will this project take? <span style={{ color: "red" }}>*</span></h5>
                        <select onChange={handleFormchange} value={formValues?.project_duration} name='project_duration' class="form-select" aria-label="Default select example">
                            <option selected>Select a duration</option>
                            <option value=">6_months">More than 6 months</option>
                            <option value="3_6_months">3 to 6 month</option>
                            <option value="1_3_months">1 to 3 months</option>
                            <option value="<1_month">Less than 1 month</option>
                        </select>
                    </div>
                    <div className="additional-detail">
                        <h5>Additional Detail <span style={{ color: "red" }}>*</span></h5>
                        <h6>Cover letter  <span onClick={handleGenerateCoverLetter}> <SettingsBackupRestoreIcon/> Generate Cover letter</span></h6>
                        <textarea name="cover_letter"
                            placeholder='Enter the few promt AI will generate your cover letter'
                            value={formValues?.cover_letter} onChange={handleFormchange} rows='10' id=""></textarea>
                    </div>


                    <button onClick={submitPropsal} className='btn btn-dark'>Send Propsal</button>

                </div>
                <ToastContainer />
            </div>

            <Footer />
        </>
    )
}

export default BidOnProject
